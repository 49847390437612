.divIncubator {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  margin: 100px 0px 50px 0px;
  box-sizing: border-box;
  padding: 0px 20px;
  position: relative;
}

.secondDivOrganisation {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  background: linear-gradient(100.11deg, #0085FF -1.64%, #5F1977 94.79%);
  margin-top: 50px;
}

.secondSubDivOrganisation{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  box-sizing: border-box;
  padding: 50px 20px;
  position: relative;
  font-size: 28px;
  text-align: center;
  gap: 20px;
}

.secondSubDivOrganisation>*:first-child{
  font-weight: 600;
}

.divNumbersOrganisation{
  display: flex;
  justify-content: center;
  gap: 20px;
}

.divNumberOrganisation{
  
}

.nbOrganisation {
  font-weight: 600;
}

.descNbOrganisation {
  font-size: 16px;
}

@media only screen and (max-width: 650px) {
  .secondSubDivOrganisation{
    gap: 40px;
  }
  .divNumbersOrganisation{
    flex-direction: column;
  }
  .nbOrganisation {
    font-size: 38px;
  }
  .descNbOrganisation {
    font-size: 24px;
  }

}