.mainHeadline {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  margin: 25vh 0px;
  box-sizing: border-box;
  padding: 0px 20px;
  position: relative;
}

.mainHeadline>div {
  display: flex;
  gap: 30px;
}


@media only screen and (max-width: 1000px) {
  .mainHeadline {
    margin: 22vh 0px;
  }
  .columnHeadline>h2{
    font-size: 4vw;
  }
}

@media only screen and (max-width: 650px) {
  .mainHeadline {
    margin: 15vh 0px;
  }
  .mainHeadline>div {
    flex-direction: column;
  }
  .columnHeadline>h2{
    font-size: 7vw;
  }
}