.mainPastSpeakers {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  margin: 150px 0px;
  box-sizing: border-box;
  padding: 0px 20px;
  position: relative;
  position: relative;
}

.carrouselPastSpeakers{
  display: flex;
  overflow-x: scroll;
  gap: 20px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.carrouselPastSpeakers::-webkit-scrollbar {
  display: none;
}

.carrouselPastSpeakers>*{
  display: flex;
  flex-direction: column;
}


.mainPastSpeakers::before{
  content: "";
  width: 80px;
  top:0px; bottom:0px; right: 0px; left: auto;
  background: linear-gradient(270deg, #000000FF 0%, #00000000 100%);
  position: absolute;
  z-index: 0;
  pointer-events: none
}

.carrouselPastSpeakers>*>*:first-child{ /*Image*/
  width: 370px;
  height: 450px;
  object-fit: cover;
  border-radius: 20px 20px 0 0;
}

.titleSpeakers {
  background-color: white;
  padding: 10px;
  border-radius: 0 0 20px 20px;
}

.titleSpeakers>a>* {
  display: flex;
  justify-content:center;
  color: black;
  font-weight: 600;
  font-size: 24px;
}



@media only screen and (max-width: 650px) {
  .carrouselPastSpeakers>*>*:first-child{ /*Image*/
    width: 310px;
    height: 400px;
    object-fit: cover;
  }
}