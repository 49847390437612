.mainDiv {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
  box-sizing: border-box;
  padding: 0 20px;
  font-weight: 600;
  position: fixed;
  top: 0;
  z-index: 1;
}

.mainDiv::before {
  content: "hhh";
  position: absolute;
  background-color: #000000;
  top: 0;
  left: -100%;
  right: -100%;
  height: 120px;
  transform: translate(0, -50%);
  z-index: -1;
  /* filter: drop-shadow(0px 5px 10px #000000); */
}

.rightHeader {
  display: flex;
  align-items: center;
}

.logoBH {
  height: 40px;
  width: auto;
}

.buttonHeader {
  padding: 10px 20px;
  border-bottom: #DDDDDD00 solid 3px;
  box-sizing: border-box;
  transition: 0.15s;
  cursor: pointer;
  text-align: center;
}

.buttonHeader:hover {
  box-sizing: border-box;
  border-bottom: #DDDDDD solid 3px;
}

nav {
  display: flex;
}

#burger {
  display: none;
  width: 30px;
  height: 25px;
  position: relative;
  cursor: pointer;
  z-index: 2;
}

#burger>* {
  position: absolute;
  right: 0;
  left: 0;
  height: 3px;
  background-color: rgb(198, 198, 198);
  border-radius: 100px;
  transition: 0.2s;
}

#burger>.up {
  top: 0;
}

#burger>.middle {
  top: 50%;
  transform: translate(0, -50%);
}

#burger>.down {
  bottom: 0;
}

#burger.OCburger>* {
  /* background-color: red; */
}

#burger.OCburger>.up {
  top: 50%;
  transform: translate(0, -50%) rotate(45deg);
}

#burger.OCburger>.middle {
  opacity: 0;
}

#burger.OCburger>.down {
  top: 50%;
  transform: translate(0, -50%) rotate(-45deg);
}

#burger:active {
  transform: translate(0px, 2px);
}

.divLogos.header {
  display: none;
  margin: 10px 0;
}

@media only screen and (max-width: 750px) {
  #burger {
      display: flex;
  }
  nav {
      position: absolute;
      top: 30px;
      right: 50%;
      transform: translate(50%, -200%);
      display: flex;
      flex-direction: column;
      background-color: #000000;
      width: 100%;
      transition: 0.2s;
      z-index: -2;
  }
  nav.hide {
      transform: translate(50%, 30px);
  }

  .divLogos.header {
    display: flex;
    margin: 5px auto 10px auto;
  }
}
