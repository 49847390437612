.mainFooter {
  width: 100%;
  max-width: 1000px;
  margin: 100px auto 50px auto;
  box-sizing: border-box;
  padding: 50px 30px 0 30px;
  display: flex;
  justify-content: space-between;
  border-top: 2px solid #ffffff10;
}

.mainFooter> {
  color: #787878;
}

.leftFooter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 8px;
}
.leftFooter>a:hover {
  color: #FFFFFF;
}

.rightFooter {
  /* background-color: black; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.divLogos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  gap: 0px 8px;
  width: fit-content;
}

.divLogos>a {
  display: flex;
  align-items: center;
  width: 18px;
  height: 18px;
  padding: 10px;
  border-radius: 50px;
  transition: 0.2s;
}

.divLogos>a:hover {
  background-color: #ffffff25;
}

.rightFooter>:last-child {
  color: #ffffff25;
  padding-right: 10px;
  text-align: end;
}

.rightFooter>:last-child>a {
  color: #ffffff48;
}



@media only screen and (max-width: 650px) {
  .divLogos {
    grid-template-columns: 1fr 1fr 1fr;
  }
}