.mainPage {
  
}

.titlePage {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  margin: 100px 0px 50px 0px;
  box-sizing: border-box;
  padding: 0px 20px;
  position: relative;
}

.waitBox {
  width: 100%;
  max-width: 1000px;
  height: 250px;
  background: #3a3a3a;
  color: black;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.divTeam {
  width: 100%;
  max-width: 1000px;
  box-sizing: border-box;
  padding: 0px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  width: 100%;
  margin-bottom: 10px;
}

.cardTeam {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  transition: 0.2s;
}

.cardTeam:hover {
  transform: scale(1.05);
}

.cardTeam>img {
  height: 220px;
  object-fit: cover;
}

.descTeam {
  width: 100%;
  background-color: white;
  color: black;
  padding: 10px 15px;
  box-sizing: border-box;
}

.bulleTeam {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: blue;
  top: -15px;
  right: -15px;
  overflow: visible;
}

.roleTeam {
  font-size: 14px;
}

.nameTeam {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (max-width: 850px) {
  .divTeam {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 650px) {
  .divTeam {
    grid-template-columns: 1fr 1fr;
  }
}