main {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #000000;
    font-family: 'Inter', sans-serif;
    color: #DDDDDD;
  
}

button {
  border: none;
  padding: 10px 30px;
  border-radius: 8px;
  color: #DDDDDD;
  cursor: pointer;
  transition: 0.2s;
  width: min-content;
  white-space: nowrap;
}

a {
    text-decoration: none;
    color: #d1d1d1;
}

img {
    width: 100%;
}

h1 {
    font-size: 128px;
    font-weight: 900;
    margin: 0;
}

h2 {
    font-size: 45px;
    font-weight: 700;
    margin: 0;
}

h3 {
    font-size: 32px;
    font-weight: 700;
    margin: 0 0 10px 0;
  
}

.radiantBluePurple {
  background: linear-gradient(94.9deg, #017BEC 46.27%, #D300BE 109.38%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

/**********/

.toAppear {
  transition: 1s ease-in-out;
  opacity: 0;
  transform: translate(0,100px);
}

.toAppear.appear {
  opacity: 1;
  transform: translate(0,0px);
}