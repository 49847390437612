.divContainer {
    width: 100%;
    height: 100px;
    background-color: red;
}

.defaultContainer {
    padding: 0 20px;
    width: 100%;
    box-sizing: border-box;
}

.btnEvent {
  border-radius: 4px;
  width: 100px;
  border: none;
  padding: 8px 16px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.13);
  color: #FFFFFF;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}

.btnEvent:hover {
  transition: 0.2s;
  background: linear-gradient(100.11deg, #0085FF -1.64%, #5F1977 94.79%);
}

.eventsDiv {
    width: 100%;
    background-color: #FFFFFF25;
    border-radius: 16px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    gap: 24px;
    overflow-x: scroll;
}

.eventsDiv::-webkit-scrollbar {
    display: none;
}

.imageMiniature {
    width: 100%;
    border-radius: 4px;
}

.eventDiv {
    border: 2px solid #555555;
    width: 300px;
    padding: 16px;
    border-radius: 20px;
}

.infoDiv {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 10px;
    border: 2px solid #555555;
    padding: 16px;
    border-radius: 4px;
}

@media only screen and (max-width: 950px) {
    .eventsDiv {
        /* background-color: red; */
    }
}

@media only screen and (max-width: 650px) {
    .titleDiv {
        font-size: 16px;
    }
    .eventsDiv {
        padding: 12px;
        gap: 10px;
    }
    .eventDiv {
        padding: 10px;
    }
    .imageMiniature {
        width: 100%;
        border-radius: 10px;
    }
}