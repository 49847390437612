.divEvents {
  width: 100%;
  max-width: 1000px;
  box-sizing: border-box;
  padding: 0px 20px 50px 20px;
}

.divHeaderEvents {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divHeaderEvents>input{
  border-radius: 35px;
  padding-left: 12px;
  height: 22px;
  border: none;
  margin-bottom: 5px;
}

.divHeaderEvents>input:hover,
.divHeaderEvents>input:focus{
  box-shadow: 0px 0px 10px #b9bcf6;
}

.divTitleAndCount {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.divCountEvents {
  background: #d6d8f5;
  padding: 5px 10px;
  color: black;
  border-radius: 8px;    
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

@media only screen and (max-width: 750px) {
  .divHeaderEvents>h3{
    font-size: 26px;
  }
  .divHeaderEvents>input{
    height: 18px;
    width: 30%;
  }

  h3 {
    font-size: 24px;
  }
  .divCountEvents {
    font-size: 14px;
  }
}