.mosaiqueDiv {
    width: 100%;
    background-color: #FFFFFF25;
    border-radius: 16px;
    padding: 12px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
}

.mosaEvent {
    border: 2px solid #555555;
    width: 100%;
    padding: 12px;
    border-radius: 16px;
    box-sizing: border-box;
}

.mosaInfoDiv {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 10px;
    border-radius: 16px;
}

.noEvents {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  grid-area: 1 / 1 / 2 / 4;
  padding: 30px 0;
}

@media only screen and (max-width: 850px) {
  .mosaiqueDiv {
      grid-template-columns: 1fr 1fr;
  }
}


@media only screen and (max-width: 650px) {
  .mosaiqueDiv {
      grid-template-columns: 1fr;
  }
}