.blockSponsors {
    width: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 30px;
    box-sizing: border-box;
    gap: 20px;
}

.subDivSponsors {
    width: 100%;
    max-width: 1000px;
    padding: 0 20px;
    box-sizing: border-box;
    color: black;
}

.divPrime {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
}

.divPrime>* {
    transition: 0.2s ease-in-out;
}

.divPrime>*:hover {
    transform: scale(1.08);
}

.prime_img {
    width: 100%;
}

.carousel {
    max-width: 1000px;
    margin: 0px;
    overflow: hidden;
}

.carousel_items {
    display: flex;
    animation: carousel 15s linear infinite;
}

.carousel_items>*>* {
    transition: 0.2s ease-in-out;
}

.carousel_items>*>*:hover {
    transform: scale(1.08);
}

.carousel_img {
    display: block;
    width: 200px;
    object-fit: cover;
}

@keyframes carousel {
    100% {
        transform: translateX(-1600px);
    }
}

@media only screen and (max-width: 650px) {
    .divPrime {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}