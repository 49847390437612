.mainNextEvent {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  position: relative;
}
.mainNextEvent>*:first-child,
.mainNextEvent>*:last-child{
  width: 100%;
  max-width: 1000px;
  padding: 0 20px;
  box-sizing: border-box;
}

.blockNextEvent {
  width: 100%;
  background: linear-gradient(100.11deg, #0085FF -1.64%, #5F1977 94.79%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 200px 30px;
  box-sizing: border-box;
  gap: 20px;
  font-size: 60px;
  font-weight: 800;
  text-align: center;
}

.blockNextEvent>* {
  text-align: center;
}

.divButtonsNextEvent {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.divButtonsNextEvent>* {
  width: 100%;
  transition: 0.2s;
}

.divButtonsNextEvent>*:hover {
  transform: scale(1.05);
}


.buttonLanding.blue{
  background: linear-gradient(100.11deg, #0085FF -1.64%, #5F1977 94.79%);
}

.buttonLanding {
  width: 100%;
  /* border: 2px solid #5F1977; */
  background: #ffffff25;
  position: relative;
  font-size: 24px;
  font-weight: 900;
}

@media only screen and (max-width: 850px) {
  .blockNextEvent>* {
    font-size: 45px;
  }
}

@media only screen and (max-width: 650px) {
  .blockNextEvent>* {
    font-size: 30px;
  }
  .divButtonsNextEvent {
      flex-direction: column;
      gap: 5px;
  }
  .buttonLanding {
      font-size: 17px;
      font-weight: 600;
  }
}
